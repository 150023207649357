


<template>

    <v-row>
        <v-col
                cols="12"
                sm="6"
        >
            <v-card
                    class="pa-2"
                    elevation="0"
            >
                <h2>{{header}}</h2>
                <v-alert type="error"
                         close-text="Закрыть сообщение"
                         dismissible
                         v-model="alertError.show"
                >
                    {{alertError.text}}
                </v-alert>
                <v-alert type="warning"
                         close-text="Закрыть сообщение"
                         dismissible
                         v-model="alertWarning.show"
                >
                    {{alertWarning.text}}
                </v-alert>
                <v-card
                        class="mt-6 pa-2"
                        elevation="10"
                        outlined
                >


                    <v-list-item-group
                            shaped
                            color="primary"
                            v-model="selectedItem"
                    >
                        <v-list-item
                                v-for="(e,i) in arr"
                                :key="i"
                                @click="onNext(i)"
                        >
                            <v-list-item-icon>
                                <v-icon v-if="e.id=='Det'"
                                        color="green"
                                >
                                    {{icon.det}}
                                </v-icon>
                                <v-icon v-else
                                        color="green"
                                >
                                    {{icon.vzr}}
                                </v-icon>

                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="e.name"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-card>
            </v-card>
        </v-col>
        <v-col
                cols="12"
                sm="6"
        >
            <v-card
                    class="pa-2"
                    elevation="0"
            >
                <h2>Выбрано</h2>
                <v-card
                        class="mt-6 pa-2"
                        elevation="0"
                        outlined
                >


                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-html="LPU.name"></v-list-item-title>
                            <v-list-item-subtitle>{{LPU.address}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{FIN.name}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </v-card>
            </v-card>
        </v-col>
    </v-row>


</template>


<script>
    import {toBack,toNext,} from '@/components/app/js/globals'
    import { mdiHuman,mdiBabyCarriage  } from '@mdi/js';
    import {postLog} from "../tools/post";

    export default {
        name: 'Age',
        data: () => ({
            name: 'Age',
            header: 'Выберите категорию приема',
            selectedItem: null,
            log: false,
            alertError: {
                show: false,
                text: null
            },
            alertWarning: {
                show: false,
                text: null
            },
            icon: {
                det: mdiBabyCarriage,
                vzr: mdiHuman
            } ,
            arr: [
                {
                    id: 'Det',
                    name: 'Детский прием'
                },
                {
                    id: 'Vzr',
                    name: 'Взрослый прием'
                }

            ]
        }),
        computed: {
            LPU() {
                return this.$store.getters.LPU;
            },
            FIN() {
                return this.$store.getters.FIN;
            },
            AGE() {
                return this.$store.getters.AGE;
            },
            is_actual() {
                return (this.$store.getters.LPU.actual && this.$store.getters.LPU.actual==='1') &&
                       (this.$store.getters.FIN.actual && this.$store.getters.FIN.actual==='1')
            },

        },
        async beforeMount() {
            try {
                if (this.is_actual) {

                } else {
                    this.alertWarning.show = true
                    this.alertWarning.text = 'Не найдено данных'
                    toBack(this)
                }
                const _AGE = this.$store.getters.AGE
                this.selectedItem = (_AGE && typeof _AGE.selectedItem === 'number' ? _AGE.selectedItem : null)

                //подтвердить актуальность selectedItem при переходах!!!
                if(this.selectedItem && this.arr.length<this.selectedItem+1){
                    this.$store.dispatch('SET_AGE_ACTUAL', '0')
                }
            }catch(err){
                this.alertError.show=true
                this.alertError.text=err
                postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Age#1' } )

            }

        },
        methods: {
            onNext(i){
                try {
                    this.$store.dispatch('SET_AGE', {selectedItem: i, ...this.arr[i]})
                    toNext(this)
                }catch(err){
                    this.alertError.show=true
                    this.alertError.text=err
                }

            }

        }



    }
</script>
